import { Link } from 'gatsby';
import React from 'react';
import Layout from '../../landing/components/layout';
import SEO from '../../landing/components/seo';

const BlogPage = () => (
  <Layout>
    <SEO title="Blog" />

    <div className={'page-header'}>
      <h1>Blog</h1>
      <p>In this blog you'll learn all about guestbooks in the modern web. Happy reading!</p>
    </div>

    <div className={'container'}>
      <div className={'article'}>
        <ul>
          <li>
            <Link to="/blog/memorista-the-ultimate-guestbook-widget-for-website-builders">
              Memorista: The ultimate guestbook widget for website builders
            </Link>
          </li>
          <li>
            <Link to="/blog/why-businesses-should-still-use-a-guestbook-on-their-website-in-2022">
              Why Businesses Should Still Use a Guestbook on Their Website in 2022
            </Link>
          </li>
        </ul>
      </div>
    </div>

    <div className={'call-to-action'}>
      <div className={'container'}>
        <div className={'call-to-action__content'}>
          <h2>Sign up for free</h2>
          <p>Start now and create your own personal Guestbook with Memorista in just 5 minutes.</p>
        </div>

        <div className={'button'}>
          <a href="/admin" target="_blank">
            Create Guestbook
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default BlogPage;
